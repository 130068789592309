import React, { useEffect, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { TypeEditTaskField, TypeTask } from '../../../types';
import { ChipGroup } from './ChipGroup';
import { ToggleChips } from './ToggleChips';
import {
  CheckCircle,
  CheckCircleOutlined,
  DeleteForever,
  DeleteOutline,
} from '@mui/icons-material';
import { uniq, without } from 'lodash';

export type TaskFilterAndSortProps = {
  showCompleted: boolean;
  onSetShowCompleted: (_checked: boolean) => void;
  showDeleted: boolean;
  onSetShowDeleted: (_checked: boolean) => void;
  isDescending: boolean;
  onSetIsDescending: (_isDescending: boolean) => void;
  orderBy: keyof TypeTask;
  onSetOrderBy: (_property: keyof TypeTask) => void;
};

export const TaskFilterAndSort = ({
  showCompleted,
  onSetShowCompleted,
  showDeleted,
  onSetShowDeleted,
  isDescending,
  onSetIsDescending,
  orderBy,
  onSetOrderBy,
}: TaskFilterAndSortProps) => {
  const [sortFields, setSortFields] = useState<TypeEditTaskField[]>([
    'name',
    'dueAt',
    'durationEstimate',
    'priorityValue',
  ]);

  useEffect(() => {
    if (showCompleted) {
      setSortFields([...sortFields, 'completedAt']);
    } else {
      setSortFields(sortFields.filter((field) => field !== 'completedAt'));
    }
  }, [showCompleted]);

  useEffect(() => {
    if (showCompleted) {
      setSortFields(uniq(sortFields.concat(['completedAt'])));
    } else {
      setSortFields(without(sortFields, 'completedAt'));
    }
  }, [showCompleted]);

  useEffect(() => {
    if (showDeleted) {
      setSortFields(uniq(sortFields.concat(['deletedAt'])));
    } else {
      setSortFields(without(sortFields, 'deletedAt'));
    }
  }, [showDeleted]);

  return (
    <Box mt={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ChipGroup
              items={sortFields}
              selectedItem={orderBy}
              onSelectItem={onSetOrderBy}
              isDescending={isDescending}
              onSetIsDescending={onSetIsDescending}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ToggleChips
              isActive={showCompleted}
              onToggle={onSetShowCompleted}
              label={'Show Completed'}
              activeIcon={<CheckCircle />}
              inactiveIcon={<CheckCircleOutlined />}
            />
            <ToggleChips
              isActive={showDeleted}
              onToggle={onSetShowDeleted}
              label={'Show Deleted'}
              activeIcon={<DeleteForever />}
              inactiveIcon={<DeleteOutline />}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
