import React, { useContext } from 'react';
import {
  Alert,
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Add, Remove } from '@mui/icons-material';
import {
  Ingredient,
  Instruction,
  RecipeHeader,
  RecipeList,
  ViewIngredient,
  ViewInstruction,
  ViewRecipeHeader,
} from '.';
import { Loader } from '../../_common';
import { ChefContext, RecipeContext, RecipeProvider } from '../../../providers';
import {
  DefaultIngredient,
  DefaultInstruction,
} from '../../../types/_common/defaults';

const RecipeWrapper = () => {
  const { editMode, setEditMode, getRecipeMultiplier, setRecipeMultiplier } =
    useContext(ChefContext);
  const { recipe, setRecipe, linkedMaterialRecipes, isRecipeReady } =
    useContext(RecipeContext);

  const addEmptyRowToIngredientList = () => {
    setRecipe({
      ...recipe,
      ingredientList: [
        ...(recipe?.ingredientList || []),
        { ...DefaultIngredient },
      ],
    });
  };

  const addEmptyRowToInstructionList = () => {
    setRecipe({
      ...recipe,
      instructionList: [
        ...(recipe?.instructionList || []),
        { ...DefaultInstruction },
      ],
    });
  };

  // const processedIngredients = useProcessIngredientNumbers(
  //   getRecipeMultiplier(recipe.id!!),
  // );

  if (!isRecipeReady) {
    return <Loader />;
  }

  return (
    <Box m={2}>
      {recipe && (
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="primary"
                    checked={editMode}
                    onChange={(_event, checked) => setEditMode(checked)}
                  />
                }
                label="Edit Mode"
                labelPlacement="start"
              />
            </Grid>
            {editMode ? <RecipeHeader /> : <ViewRecipeHeader />}
            <Grid item xs={12}>
              <Typography variant="h5">Ingredients</Typography>
            </Grid>
            <Grid item xs={12}>
              {editMode ? (
                <Alert severity="info">
                  Use fractions or whole numbers for quantity.
                </Alert>
              ) : (
                <>
                  <IconButton
                    onClick={() => setRecipeMultiplier(recipe.id!!, false)}
                  >
                    <Remove />
                  </IconButton>
                  <Button>{`${getRecipeMultiplier(recipe.id!!)}x`}</Button>
                  <IconButton
                    onClick={() => setRecipeMultiplier(recipe.id!!, true)}
                  >
                    <Add />
                  </IconButton>
                </>
              )}
            </Grid>
            {recipe.ingredientList?.map((ingredient, ingredientIndex) =>
              editMode ? (
                <Ingredient
                  key={`ingredient-${ingredientIndex}`}
                  ingredient={ingredient}
                  ingredientIndex={ingredientIndex}
                />
              ) : (
                <ViewIngredient
                  key={`ingredient-${ingredientIndex}`}
                  recipeId={recipe.id!!}
                  ingredient={ingredient}
                />
              ),
            )}
            {editMode && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={addEmptyRowToIngredientList}
                  startIcon={<Add />}
                >
                  Add Ingredient
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h5">Instructions</Typography>
            </Grid>
            {recipe.instructionList?.map((instruction, instructionIndex) =>
              editMode ? (
                <Instruction
                  key={`instruction-${instructionIndex}`}
                  instruction={instruction}
                  instructionIndex={instructionIndex}
                />
              ) : (
                <ViewInstruction
                  key={`instruction-${instructionIndex}`}
                  instruction={instruction}
                  instructionIndex={instructionIndex}
                />
              ),
            )}
            {editMode && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={addEmptyRowToInstructionList}
                  startIcon={<Add />}
                >
                  Add Instruction
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              {recipe?.isMaterial && (
                <RecipeList
                  recipes={linkedMaterialRecipes}
                  recipesAvailable={!!linkedMaterialRecipes}
                  flatList
                />
              )}
            </Grid>
          </Grid>
        </Container>
      )}
    </Box>
  );
};

export const Recipe = () => {
  const { recipeId } = useParams();
  const recipeIdNum = Number(recipeId);
  if (!recipeIdNum) return <></>;

  return (
    <RecipeProvider recipeId={recipeIdNum}>
      <RecipeWrapper />
    </RecipeProvider>
  );
};
