import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';

interface DurationInputProps {
  duration: number;
  onDurationChange: (_duration: number) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const DurationInput: React.FC<DurationInputProps> = ({
  duration,
  onDurationChange,
  inputRef,
}) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    const totalSeconds = duration;
    const days = Math.floor(totalSeconds / 86400);
    const hours = Math.floor((totalSeconds % 86400) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    setDays(days);
    setHours(hours);
    setMinutes(minutes);
  }, [duration]);

  useEffect(() => {
    const totalSeconds = days * 86400 + hours * 3600 + minutes * 60;
    onDurationChange(totalSeconds);
  }, [days, hours, minutes, onDurationChange]);

  const handleDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10) || 0;
    setDays(value);
  };

  const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10) || 0;
    setHours(value);
  };

  const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10) || 0;
    setMinutes(value);
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <TextField
        label="Days"
        type="number"
        value={days}
        onChange={handleDaysChange}
        InputProps={{ inputProps: { min: 0 } }}
      />
      <TextField
        label="Hours"
        type="number"
        value={hours}
        onChange={handleHoursChange}
        InputProps={{ inputProps: { min: 0 } }}
      />
      <TextField
        label="Minutes"
        type="number"
        value={minutes}
        onChange={handleMinutesChange}
        inputRef={inputRef}
        InputProps={{ inputProps: { min: 0 } }}
      />
    </Box>
  );
};
