import React from 'react';
import { Chip } from '@mui/material';
import { format, parseISO } from 'date-fns';

type DateViewProps = {
  title: string;
  date?: string;
};
export const DateView = ({ title, date }: DateViewProps) => {
  const formattedDate = date
    ? format(parseISO(date), 'MMM do hh:mmaaa')
    : 'Not Set';
  return <Chip label={`${title}: ${formattedDate}`} color="default" />;
};
