import React from 'react';

import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ErrorBoundary, Router } from './';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { AuthProvider, DialogProvider, ProjectProvider } from '../providers';
import { IntercomProvider } from 'react-use-intercom';
import { BrowserRouter } from 'react-router-dom';
import { StompSessionProvider } from 'react-stomp-hooks';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FeatureFlagProvider } from '../providers/FeatureFlagProvider';
import { AppearanceProvider } from '../providers/AppearanceProvider';

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});
const queryClient = new QueryClient();
const wsUrl = process.env.REACT_APP_WS_URL || 'ws://localhost:9090/websocket';

export const App = () => {
  const intercomAppID = 'vmgjqf1u';

  return (
    <ErrorBoundary>
      <CacheProvider value={muiCache}>
        <QueryClientProvider client={queryClient}>
          <ProjectProvider>
            <IntercomProvider appId={intercomAppID}>
              <FeatureFlagProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DialogProvider>
                    <StompSessionProvider url={wsUrl}>
                      <BrowserRouter>
                        <AuthProvider>
                          <AppearanceProvider>
                            <CssBaseline />
                            <Router />
                          </AppearanceProvider>
                        </AuthProvider>
                      </BrowserRouter>
                    </StompSessionProvider>
                  </DialogProvider>
                </LocalizationProvider>
              </FeatureFlagProvider>
            </IntercomProvider>
          </ProjectProvider>
        </QueryClientProvider>
      </CacheProvider>
    </ErrorBoundary>
  );
};
