import React, { useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { useGetTagsByType, usePostTag } from '../../../api';
import { TagList } from './TagList';
import { TypeTagEntityMap } from '../../../types';

export type TagsProps = {
  type: string;
  mapFieldName: string;
  map: TypeTagEntityMap;
};

export const Tags = ({ type, mapFieldName, map }: TagsProps) => {
  const [tagName, setTagTitle] = useState<string>('');
  const { ...queryInfo } = useGetTagsByType(type);
  const tagMutation = usePostTag();

  const createTag = () => {
    if (tagName === '') return;
    tagMutation.mutate({
      name: tagName,
      type: type,
    });
    setTagTitle('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagTitle(event.target.value);
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="tagName"
              value={tagName}
              label="Search or Add Tag"
              placeholder="Tag Name"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="outlined"
              disabled={tagName === ''}
              onClick={createTag}
            >
              Create Tag
            </Button>
          </Grid>
        </Grid>
        <TagList
          tags={queryInfo.data}
          tagsAvailable={!queryInfo.isLoading}
          type={type}
          mapFieldName={mapFieldName}
          map={map}
        />
      </Container>
    </Box>
  );
};
