import React from 'react';

export const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-logo">
            <img src="/images/logo.svg" alt="Logo" />
          </div>
          <div className="footer-links">
            <a href="/about">About</a>
            <a href="/contact">Contact</a>
            <a href="/faq">FAQ</a>
          </div>
        </div>
        <div className="footer-social">
          <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
            <img src="/images/facebook.svg" alt="Facebook" />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
            <img src="/images/instagram.svg" alt="Instagram" />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noreferrer">
            <img src="/images/twitter.svg" alt="Twitter" />
          </a>
        </div>
      </div>
    </footer>
  );
};
