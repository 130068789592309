import {
  TypeEditTaskField,
  TypeTag,
  TypeTask,
  TypeTaskLink,
} from '../../../types';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import Autocomplete from '@mui/material/Autocomplete';
import {
  AddLink,
  ChevronRight,
  Clear,
  Event,
  ExpandMore,
  NotificationsPaused,
  Replay,
  Save,
} from '@mui/icons-material';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AuthContext, ProjectContext } from '../../../providers';
import { useGetTagsByType } from '../../../api';
import { format, parseISO } from 'date-fns';
import { DurationInput } from '../../_common';

type EditTaskProps = {
  task: TypeTask;
  focusField: TypeEditTaskField | null;
  onSave: (_task: TypeTask) => void;
  onReset: (_task: TypeTask | null) => void;
  taskNames: string[];
};

export const EditTaskCard = ({
  task,
  focusField,
  onSave,
  onReset,
  taskNames,
}: EditTaskProps) => {
  const { performingAction, isMediumScreen } = useContext(ProjectContext);
  const { profile } = useContext(AuthContext);
  const [editTaskName, setEditTaskName] = useState<string>('');
  const [editTaskStartAt, setEditTaskStartAt] = useState<Date | null>(null);
  const [editTaskEndAt, setEditTaskEndAt] = useState<Date | null>(null);
  const [editTaskDurationEstimate, setEditTaskDurationEstimate] =
    useState<number>(0);
  const [editTaskSnoozeUntil, setEditTaskSnoozeUntil] = useState<Date | null>(
    null,
  );
  const [editTaskDueAt, setEditTaskDueAt] = useState<Date | null>(null);
  const [editTaskLinks, setEditTaskLinks] = useState<TypeTaskLink[]>([]);
  const [editTaskTags, setEditTaskTags] = useState<TypeTag | null>(null);
  const [showAddEvent, setShowAddEvent] = useState<boolean>(false);
  const [showAddSnooze, setShowAddSnooze] = useState<boolean>(false);

  const [expanded, setExpanded] = useState<boolean>(true);

  const { data: taskTags } = useGetTagsByType('task');
  const editTaskNameRef = useRef<HTMLInputElement | null>(null);

  const handleEdit = (task: TypeTask) => {
    setEditTaskName(task?.name || '');

    setShowAddEvent(!!task.startAt || !!task.endAt || !!task.durationEstimate);
    setEditTaskStartAt(task.startAt ? parseISO(task.startAt) : null);
    setEditTaskEndAt(task.createdAt ? parseISO(task.createdAt) : null);
    setEditTaskDurationEstimate(task?.durationEstimate || 0);

    setShowAddSnooze(!!task.snoozeUntil);
    setEditTaskSnoozeUntil(
      task.snoozeUntil ? parseISO(task.snoozeUntil) : null,
    );

    setEditTaskDueAt(task.dueAt ? parseISO(task.dueAt) : null);
    setEditTaskTags(task.tags?.length ? task.tags[0] : null);

    setEditTaskLinks(task.taskLinks || []);
  };

  const ensureTags = (tag: TypeTag | null) => {
    if (!tag) {
      return [];
    }
    return [
      {
        ...tag,
        type: 'task',
        position: 1,
        organization: profile?.organizationContext,
      },
    ];
  };

  const handleSave = () => {
    onSave({
      ...task,
      name: editTaskName,
      startAt: editTaskStartAt
        ? format(editTaskStartAt, `yyyy-MM-dd'T'HH:mm:ss`)
        : undefined,
      endAt: editTaskEndAt
        ? format(editTaskEndAt, `yyyy-MM-dd'T'HH:mm:ss`)
        : undefined,
      dueAt: editTaskDueAt
        ? format(editTaskDueAt, `yyyy-MM-dd'T'HH:mm:ss`)
        : undefined,
      snoozeUntil: editTaskSnoozeUntil
        ? format(editTaskSnoozeUntil, `yyyy-MM-dd'T'HH:mm:ss`)
        : undefined,
      durationEstimate: editTaskDurationEstimate,
      tags: ensureTags(editTaskTags),
      taskLinks: editTaskLinks,
      profile: profile ?? undefined,
      organization: profile?.organizationContext,
    });
  };

  useEffect(() => {
    console.log('focusField', focusField, task);
    if (focusField === 'name') {
      editTaskNameRef.current?.focus();
    }
    handleEdit(task);
  }, [task, focusField]);

  const handleGetOptionLabel = (option: string | TypeTag) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.name || '';
  };

  const handleTagOptionEqualToValue = (
    option: TypeTag,
    value: TypeTag | string,
  ) => (typeof value === 'string' ? option.name === value : option === value);

  const handleTagsChange = (
    _event: React.SyntheticEvent,
    newValue: TypeTag | string | null,
  ) => {
    if (!newValue) {
      return;
    }
    setEditTaskTags(
      typeof newValue === 'string'
        ? { name: newValue, type: 'task' }
        : newValue,
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const handleAddLink = () => {
    setEditTaskLinks([
      ...editTaskLinks,
      { url: '', organization: profile?.organizationContext },
    ]);
  };

  const handleEditTaskLink = (index: number, url: string) => {
    setEditTaskLinks(
      editTaskLinks.map((link, i) => (i === index ? { ...link, url } : link)),
    );
  };

  const handleRemoveTaskLink = (index: number) => {
    setEditTaskLinks(editTaskLinks.filter((_link, i) => i !== index));
  };

  const actions = () => (
    <Box display="flex" justifyContent="flex-end">
      <IconButton onClick={handleSave}>
        <Save />
      </IconButton>
      <IconButton onClick={() => setExpanded(!expanded)}>
        {expanded ? <ExpandMore /> : <ChevronRight />}
      </IconButton>
      <IconButton onClick={() => setShowAddEvent(true)}>
        <Event />
      </IconButton>
      <IconButton onClick={() => setShowAddSnooze(true)}>
        <NotificationsPaused />
      </IconButton>
      <IconButton onClick={handleAddLink}>
        <AddLink />
      </IconButton>
      <IconButton onClick={() => onReset(null)}>
        <Replay />
      </IconButton>
    </Box>
  );

  const startAtEndAt = () => (
    <>
      <Grid item xs={6} md={3}>
        <DateTimePicker
          label={'Start At'}
          value={editTaskStartAt}
          onChange={(newValue) => setEditTaskStartAt(newValue)}
          disabled={performingAction}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <DateTimePicker
          label={'End At'}
          value={editTaskStartAt}
          onChange={(newValue) => setEditTaskStartAt(newValue)}
          disabled={performingAction}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <DurationInput
          duration={editTaskDurationEstimate}
          onDurationChange={setEditTaskDurationEstimate}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <IconButton onClick={() => setShowAddEvent(false)}>
          <Clear />
        </IconButton>
      </Grid>
    </>
  );

  const snoozeFields = () => (
    <>
      <Grid item xs={6} md={6}>
        <DateTimePicker
          label={'Snooze Until'}
          value={editTaskStartAt}
          onChange={(newValue) => setEditTaskStartAt(newValue)}
          disabled={performingAction}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <IconButton onClick={() => setShowAddSnooze(false)}>
          <Clear />
        </IconButton>
      </Grid>
    </>
  );

  const mainFields = () => (
    <>
      <Grid item xs={6} md={3}>
        <DateTimePicker
          label={'Due Date'}
          value={editTaskDueAt}
          onChange={(newValue) => setEditTaskDueAt(newValue)}
          disabled={performingAction}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Autocomplete
          freeSolo
          clearOnBlur
          value={editTaskTags}
          options={taskTags || []}
          getOptionLabel={handleGetOptionLabel}
          filterSelectedOptions
          isOptionEqualToValue={handleTagOptionEqualToValue}
          onChange={handleTagsChange}
          renderInput={(params) => (
            <TextField {...params} label="Project" placeholder="Project" />
          )}
          disabled={performingAction}
        />
      </Grid>
    </>
  );

  return (
    <Box mt={2} mb={2}>
      <Container>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {isMediumScreen && (
                <Grid item xs={12}>
                  {actions()}
                </Grid>
              )}
              <Grid item xs={12} md={9}>
                <Autocomplete
                  freeSolo
                  disableClearable
                  value={editTaskName}
                  options={taskNames || []}
                  getOptionLabel={(option) => option || ''}
                  filterSelectedOptions
                  isOptionEqualToValue={(option, value) => option === value}
                  onInputChange={(event, newInputValue) => {
                    setEditTaskName(newInputValue);
                  }}
                  onKeyDown={handleKeyPress}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      multiline
                      label="Task Name"
                      placeholder="Task Name"
                      inputRef={editTaskNameRef}
                    />
                  )}
                  disabled={performingAction}
                />
              </Grid>
              {!isMediumScreen && (
                <Grid item md={3}>
                  {actions()}
                </Grid>
              )}
              {showAddEvent && startAtEndAt()}
              {showAddSnooze && snoozeFields()}
              {editTaskLinks.map((taskLink, index) => (
                <Fragment key={`edit-task-links-${index}`}>
                  <Grid item xs={9}>
                    <TextField
                      fullWidth
                      label={`Link ${index + 1}`}
                      placeholder={`Link ${index + 1}`}
                      value={taskLink.url}
                      onChange={(event) =>
                        handleEditTaskLink(index, event.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <IconButton onClick={() => handleRemoveTaskLink(index)}>
                      <Clear />
                    </IconButton>
                  </Grid>
                </Fragment>
              ))}
              {expanded && mainFields()}
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};
