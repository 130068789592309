import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TypeOrgInvite } from '../../../types';

export const OrgInviteList = ({
  orgInvites,
  orgInvitesAvailable,
}: {
  orgInvites: TypeOrgInvite[] | undefined;
  orgInvitesAvailable: boolean;
}) => (
  <TableContainer hidden={!orgInvitesAvailable}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>Roles</TableCell>
          <TableCell>Organization</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orgInvites?.map((orgInvite) => (
          <TableRow key={orgInvite.id}>
            <TableCell>{orgInvite.email}</TableCell>
            <TableCell>
              {orgInvite.authorities?.map((role) => role.authority).join(', ')}
            </TableCell>
            <TableCell>{orgInvite.organization?.name}</TableCell>
            <TableCell>{orgInvite.accepted ? 'Accepted' : 'Pending'}</TableCell>
          </TableRow>
        ))}
        {orgInvites?.length === 0 && (
          <TableRow>
            <TableCell colSpan={4}>No Org Invites Yet</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);
