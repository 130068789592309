import { ISorter } from './ISorter';
import { isValid, parseISO } from 'date-fns';
import { sortBy } from 'lodash';

export const stableSort = <T,>(array: T[], sorter: ISorter<T>) => {
  const sorted = sortBy(array, (item) => {
    const value = item[sorter.property];

    if (!value) {
      return Infinity;
    }

    if (typeof value === 'string') {
      if (isValid(parseISO(value))) {
        return parseISO(value).getTime();
      } else {
        return value.toLocaleLowerCase();
      }
    }

    if (Array.isArray(value)) {
      return value[0];
    }

    return value;
  });

  return sorter.isDescending ? sorted.reverse() : sorted;
};
