import React, { useContext, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { AuthContext } from '../../providers';

export const IntercomInstance = () => {
  const { user, profile } = useContext(AuthContext);
  const { boot, shutdown } = useIntercom();
  useEffect(() => {
    shutdown();
    if (!user || !profile?.hmac) {
      boot();
    } else {
      boot({
        email: user.email || '',
        name: profile?.name || user.displayName || '',
        userHash: profile.hmac,
      });
    }
  }, [user, profile]);
  return <></>;
};
