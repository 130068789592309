import { ISorter } from './ISorter';

// comparator function for any property within type T
// works for: strings, numbers, and Dates (and is typed to accept only properties which are those types)
// could be extended for other types but would need some custom comparison function here
export const genericSort = <T,>(objectA: T, objectB: T, sorter: ISorter<T>) => {
  const result = () => {
    const aValue = objectA[sorter.property];
    const bValue = objectB[sorter.property];

    if (aValue === null && bValue === null) return 0;
    if (!aValue) return 1;
    if (!bValue) return -1;
    if (Array.isArray(aValue) && Array.isArray(bValue)) {
      return aValue[0] > bValue[0] ? 1 : -1;
    }
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return aValue.localeCompare(bValue);
    }
    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return aValue - bValue;
    }
    return 0;
  };

  return sorter.isDescending ? result() * -1 : result();
};
