import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Container, Grid, List } from '@mui/material';
import { TypeEditTaskField, TypeTag, TypeTask } from '../../../types';
import { useGetTasks, usePostTask } from '../../../api';
import { ProjectContext } from '../../../providers';
import { TagsHeader } from './TagsHeader';
import { stableSort } from '../../_common';
import { TaskFilterAndSort } from './TaskFilterAndSort';
import { EditTaskCard } from './EditTaskCard';
import { ViewTaskCard } from './ViewTaskCard';

export const TaskList = () => {
  const { setPerformingAction, setSnackbar, isMediumScreen } =
    useContext(ProjectContext);
  const [newTask, setNewTask] = useState<TypeTask>({ tags: [] });
  const [activeTask, setActiveTask] = useState<TypeTask | null>(null);
  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<TypeTag[]>([]);
  const [focusField, setFocusField] = useState<TypeEditTaskField | null>(null);
  const [isDescending, setIsDescending] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<keyof TypeTask>('dueAt');

  const { data: tasksData, isLoading: tasksLoading } = useGetTasks();
  const { mutate: updateTask } = usePostTask();

  const tasks = useMemo(() => {
    console.log('sorting', tasksData, orderBy, isDescending, selectedTags);
    if (!tasksData) {
      return [];
    }
    const filteredTasks = tasksData
      .filter((task) =>
        selectedTags.every((t) => task.tags?.some((tt) => tt.id === t.id)),
      )
      .filter((task) => (showCompleted ? true : !task.completedAt))
      .filter((task) => (showDeleted ? true : !task.deletedAt));
    return stableSort<TypeTask>(filteredTasks, {
      property: orderBy,
      isDescending,
    });
  }, [
    tasksData,
    newTask,
    orderBy,
    isDescending,
    selectedTags,
    showCompleted,
    showDeleted,
  ]);

  const taskNames = useMemo(() => {
    console.log('taskNames', tasks);
    return tasks.map((task) => task.name || '');
  }, [tasks]);

  useEffect(() => {
    setNewTask({
      ...newTask,
      tags: selectedTags,
    });
  }, [selectedTags]);

  const editTask = useMemo(() => {
    return activeTask ?? newTask;
  }, [newTask, activeTask]);

  const handleSetEditTask = (
    task: TypeTask,
    focusField: TypeEditTaskField | null,
  ) => {
    setFocusField(focusField);
    setActiveTask(task);
  };

  const handleReset = (task: TypeTask | null) => {
    setActiveTask(null);
    setFocusField(null);
    setNewTask({
      tags: selectedTags,
    });
    if (task === null) {
      setSnackbar('Reset New Task');
    }
  };

  const handleSave = (updatedTask: TypeTask) => {
    setPerformingAction(true);
    updateTask(updatedTask, {
      onSuccess: (data) => {
        setSnackbar(`Saved: ${data.name}`);
        handleReset(data);
      },
      onSettled: () => setPerformingAction(false),
    });
  };

  const handleSetShowCompleted = (checked: boolean) => {
    setShowCompleted(checked);
  };

  const handleSetShowDeleted = (checked: boolean) => {
    setShowDeleted(checked);
  };

  const handleSelectTag = (tag: TypeTag) => {
    console.log('selected tag', tag, selectedTags);
    if (selectedTags.some((t) => t.id === tag.id)) {
      setSelectedTags([]);
    } else {
      setSelectedTags([tag]);
    }
  };

  const allTasksComponent = (tasks: TypeTask[]) =>
    tasks?.map((task) => (
      <ViewTaskCard
        key={`task-${task.id}`}
        task={task}
        activeTask={activeTask}
        onSetEditTask={handleSetEditTask}
        onSave={handleSave}
      />
    ));

  if (tasksLoading) {
    return <></>;
  }

  return (
    <>
      <TagsHeader
        type={'task'}
        selectedTags={selectedTags}
        onSelectTag={handleSelectTag}
      />
      <TaskFilterAndSort
        showCompleted={showCompleted}
        onSetShowCompleted={handleSetShowCompleted}
        showDeleted={showDeleted}
        onSetShowDeleted={handleSetShowDeleted}
        isDescending={isDescending}
        onSetIsDescending={setIsDescending}
        orderBy={orderBy}
        onSetOrderBy={setOrderBy}
      />
      <EditTaskCard
        key={`task-edit`}
        task={editTask}
        focusField={focusField}
        onSave={handleSave}
        onReset={handleReset}
        taskNames={taskNames}
      />
      <Box>
        <Container>
          {isMediumScreen ? (
            <List>{allTasksComponent(tasks)}</List>
          ) : (
            <Grid container spacing={2}>
              {allTasksComponent(tasks)}
            </Grid>
          )}
        </Container>
      </Box>
    </>
  );
};
