import React, { useContext, useEffect, useState } from 'react';
import { useGetAnnouncements } from '../../../api';
import { Box, Container, Grid, IconButton } from '@mui/material';
import { AuthContext } from '../../../providers';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { TypeAnnouncement } from '../../../types';

export const UserAnnouncements = () => {
  const { profile } = useContext(AuthContext);
  const { data: announcementsData } = useGetAnnouncements({
    enabled: !!profile,
  });
  const [announcementIndex, setAnnouncementIndex] = useState(0);
  const [viewAnnouncement, setViewAnnouncement] =
    useState<TypeAnnouncement | null>(null);
  useEffect(() => {
    setViewAnnouncement(announcementsData?.[announcementIndex] || null);
  }, [announcementsData, announcementIndex]);
  const setAnnouncement = (value: boolean) => {
    if (value) {
      setAnnouncementIndex(announcementIndex + 1);
    } else {
      setAnnouncementIndex(announcementIndex - 1);
    }
  };
  return (
    <Box mt={2}>
      <Container>
        <Grid container spacing={2}>
          {viewAnnouncement && (
            <Grid item xs={12}>
              <h1>{viewAnnouncement.announcementTitle}</h1>
              <p>{viewAnnouncement.announcementNotes}</p>
            </Grid>
          )}
          {announcementsData && announcementsData?.length > 1 && (
            <Grid item xs={12}>
              <IconButton
                onClick={() => setAnnouncement(false)}
                disabled={announcementIndex === 0}
              >
                <ChevronLeft />
              </IconButton>
              <IconButton
                onClick={() => setAnnouncement(true)}
                disabled={announcementIndex === announcementsData.length - 1}
              >
                <ChevronRight />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};
