import React, { createContext, useMemo, useState } from 'react';
import { TypeAppRoute, TypeRoleOptions } from '../types';
import { Cloudinary } from '@cloudinary/url-gen';
import {
  Account,
  AdminAppointments,
  Announcement,
  Announcements,
  BodyGroup,
  BodyGroups,
  CanvaLinkContent,
  ChefHome,
  CoachDashboard,
  CoachHome,
  CoachTeams,
  Courses,
  Dashboard,
  Directions,
  DiscussionTopic,
  DiscussionTopics,
  Image,
  Images,
  ImageTag,
  ImageTags,
  League,
  Leagues,
  Material,
  Materials,
  Measurement,
  Measurements,
  MeetingRecap,
  MeetingRecaps,
  Memberships,
  Orders,
  PBSHome,
  PrivacyPolicy,
  Questionnaire,
  QuestionnaireAnswer,
  QuestionnaireQuestion,
  Recipe,
  Recipes,
  RecipeTag,
  RecipeTags,
  Store,
  StripePrices,
  StripeProducts,
  TaskList,
  TaskTags,
} from '../components';
import { TypeProjectConfigs } from '../types/_common/ProjectConfig';
import { AlertColor, useMediaQuery } from '@mui/material';

interface SnackbarData {
  autoHideDuration: number;
  message: string;
  severity?: AlertColor;
  open: boolean;
}

type TypeHomeRoutes = {
  // eslint-disable-next-line no-unused-vars
  [_key in TypeRoleOptions]?: string;
};

type ProjectContextType = {
  appName: string;
  projectName: string;
  webUrl: string;
  appRoutes: TypeAppRoute[];
  homeRoutes: TypeHomeRoutes;
  cld?: Cloudinary;
  cldCloudName?: string;
  cldUploadPreset?: string;
  performingAction: boolean;
  setPerformingAction: (_performingAction: boolean) => void;
  mobileOpen: boolean;
  toggleMobileOpen: () => void;
  snackbarData: SnackbarData;
  setSnackbar: (_message?: string, _severity?: AlertColor) => void;
  isSmallScreen: boolean;
  isMediumScreen: boolean;
};

export const ProjectContext = createContext<ProjectContextType>({
  appName: '',
  projectName: '',
  webUrl: '',
  appRoutes: [],
  homeRoutes: {},
  cld: undefined,
  cldCloudName: '',
  cldUploadPreset: '',
  performingAction: false,
  setPerformingAction: (_performingAction: boolean) => {},
  mobileOpen: false,
  toggleMobileOpen: () => {},
  snackbarData: { autoHideDuration: 6000, message: '', open: false },
  setSnackbar: (_message?: string, _severity?: AlertColor) => {},
  isSmallScreen: false,
  isMediumScreen: false,
});

export type ProjectSwitcherProviderProps = {
  children: React.ReactNode;
};

export const ProjectProvider = ({ children }: ProjectSwitcherProviderProps) => {
  // Define the data arrays based on the feature flag
  let appRoutes: TypeAppRoute[];
  let homeRoutes: TypeHomeRoutes;
  let cld: Cloudinary | undefined;
  let cldCloudName: string | undefined = undefined;
  let cldUploadPreset: string | undefined;
  const [performingAction, setPerformingAction] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState<SnackbarData>({
    autoHideDuration: 6000,
    message: '',
    severity: 'info',
    open: false,
  });
  const isSmallScreen = useMediaQuery('(max-width:599px)'); // Adjust the breakpoint as needed
  const isMediumScreen = useMediaQuery('(max-width:899px)'); // Adjust the breakpoint as needed
  const appName = process.env.REACT_APP_TITLE || '';
  const projectName = process.env.REACT_APP_PROJECT || '';
  const webUrl = process.env.REACT_APP_WEB_URL || '';

  const getReadingTimeMS = (text: string) =>
    Math.min(Math.max(text.split(/\W+/g).length * 300, 2000), 5000);

  const setSnackbar = (message?: string, severity?: AlertColor) => {
    if (message) {
      const duration = getReadingTimeMS(message);
      setSnackbarData({
        autoHideDuration: duration,
        message: message,
        severity: severity || 'success',
        open: true,
      });
    } else {
      setSnackbarData({
        autoHideDuration: 5000,
        message: '',
        severity: undefined,
        open: false,
      });
    }
  };

  const commonAppRoutes: TypeAppRoute[] = [
    {
      name: 'Stripe Products',
      path: '/stripe-products',
      component: StripeProducts,
      roles: ['SYSADMIN'],
      showInDrawer: true,
    },
    {
      name: 'Stripe Product',
      path: '/stripe-product/:stripeProductId',
      component: StripePrices,
      roles: ['SYSADMIN'],
    },
    {
      name: 'Account',
      path: '/account',
      component: Account,
    },
    {
      name: 'Privacy Policy',
      path: '/privacy-policy',
      component: PrivacyPolicy,
      public: true,
    },
  ];

  const featureConfig: TypeProjectConfigs = {
    chef: {
      homeRoutes: {
        SYSADMIN: '/dashboard',
        ADMIN: '/dashboard',
      },
      appRoutes: [
        {
          name: 'Practice Makes Chef',
          path: '/',
          component: ChefHome,
          showInDrawer: true,
        },
        {
          name: 'Dashboard',
          path: '/dashboard',
          component: ChefHome,
          showInDrawer: true,
        },
        {
          name: 'Announcements',
          path: '/announcements',
          component: Announcements,
          roles: ['ADMIN', 'SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Announcement',
          path: '/announcement/:announcementId',
          component: Announcement,
          roles: ['ADMIN', 'SYSADMIN'],
        },
        {
          name: 'Discussion Topics',
          path: '/discussion-topics',
          component: DiscussionTopics,
          roles: ['ADMIN', 'SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Discussion Topic',
          path: '/discussion-topic/:discussionTopicId',
          component: DiscussionTopic,
          roles: ['ADMIN', 'SYSADMIN'],
        },
        {
          name: 'Materials',
          path: '/materials',
          component: Materials,
          roles: ['ADMIN', 'SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Material',
          path: '/material/:materialId',
          component: Material,
          roles: ['ADMIN', 'SYSADMIN'],
        },
        {
          name: 'Measurements',
          path: '/measurements',
          component: Measurements,
          roles: ['ADMIN', 'SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Measurement',
          path: '/measurement/:measurementId',
          component: Measurement,
          roles: ['ADMIN', 'SYSADMIN'],
        },
        {
          name: 'Meeting Recaps',
          path: '/meeting-recaps',
          component: MeetingRecaps,
          roles: ['ADMIN', 'SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Meeting Recap',
          path: '/meeting-recap/:meetingRecapId',
          component: MeetingRecap,
          roles: ['ADMIN', 'SYSADMIN'],
        },
        {
          name: 'Recipes',
          path: '/recipes',
          component: Recipes,
          roles: ['ADMIN', 'SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Recipe',
          path: '/recipe/:recipeId',
          component: Recipe,
          roles: ['ADMIN', 'SYSADMIN'],
        },
        {
          name: 'Recipe Tags',
          path: '/recipe-tags',
          component: RecipeTags,
          roles: ['ADMIN', 'SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Recipe Tag',
          path: '/recipe-tag/:tagId',
          component: RecipeTag,
          roles: ['ADMIN', 'SYSADMIN'],
        },
        ...commonAppRoutes,
      ],
      cldCloudName: 'hpgzbe5ye',
      cldUploadPreset: 'lkt5kgew',
    },
    coach: {
      homeRoutes: {
        SYSADMIN: '/dashboard',
        ADMIN: '/dashboard',
        EMPLOYEE: '/dashboard',
      },
      appRoutes: [
        {
          name: 'Home',
          path: '/',
          component: CoachHome,
          showInDrawer: true,
        },
        {
          name: 'Dashboard',
          path: '/dashboard',
          component: CoachDashboard,
          showInDrawer: true,
        },
        {
          name: 'Tasks',
          path: '/tasks',
          component: TaskList,
          roles: ['ADMIN', 'SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Task Tags',
          path: '/task-tags',
          component: TaskTags,
          roles: ['ADMIN', 'SYSADMIN'],
          showInDrawer: true,
        },
        ...commonAppRoutes,
      ],
      cldCloudName: 'hkgss7uwm',
      cldUploadPreset: 'tvlwmfw3',
    },
    florist: {
      homeRoutes: {
        SYSADMIN: '/directions',
        ADMIN: '/directions',
        EMPLOYEE: '/directions',
      },
      appRoutes: [
        {
          name: 'Home',
          path: '/',
          component: ChefHome,
          showInDrawer: true,
        },
        {
          name: 'Directions',
          path: '/directions',
          component: Directions,
          roles: ['ADMIN', 'SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Orders',
          path: '/orders',
          component: Orders,
          roles: ['ADMIN', 'SYSADMIN'],
          showInDrawer: true,
        },
        ...commonAppRoutes,
      ],
      cldCloudName: 'hkgss7uwm',
      cldUploadPreset: 'tvlwmfw3',
    },
    pbs: {
      homeRoutes: {
        ADMIN: '/appointments',
        SYSADMIN: '/appointments',
        USER: '/dashboard',
      },
      appRoutes: [
        {
          name: 'Home',
          path: '/',
          component: PBSHome,
          hideNavbar: true,
          showInDrawer: true,
        },
        {
          name: 'Dashboard',
          path: '/dashboard',
          component: Dashboard,
          showInDrawer: true,
        },
        {
          name: 'Announcements',
          path: '/announcements',
          component: Announcements,
          roles: ['ADMIN', 'SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Announcement',
          path: '/announcement/:announcementId',
          component: Announcement,
          roles: ['ADMIN', 'SYSADMIN'],
        },
        {
          name: 'Appointments',
          path: '/appointments',
          component: AdminAppointments,
          roles: ['ADMIN', 'SYSADMIN', 'THERAPIST'],
          showInDrawer: true,
        },
        {
          name: 'Body Groups',
          path: '/body-groups',
          component: BodyGroups,
          roles: ['ADMIN', 'SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Body Group',
          path: '/body-group/:bodyGroupId',
          component: BodyGroup,
          roles: ['ADMIN', 'SYSADMIN'],
        },
        // {
        //   name: 'Canva Links',
        //   path: '/canva-links',
        //   component: CanvaLinks,
        //   roles: ['ADMIN', 'SYSADMIN'],
        //   showInDrawer: true,
        // },
        // {
        //   name: 'Canva Link Tags',
        //   path: '/canva-link-tags',
        //   component: CanvaLinkTags,
        //   roles: ['ADMIN', 'SYSADMIN'],
        //   showInDrawer: true,
        // },
        // {
        //   name: 'Canva Link Tag',
        //   path: '/canva-link-tag/:tagId',
        //   component: CanvaLinkTag,
        //   roles: ['ADMIN', 'SYSADMIN'],
        // },
        {
          name: 'Memberships',
          path: '/memberships',
          component: Memberships,
          roles: ['SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Membership',
          path: '/membership/:membershipId',
          component: Memberships,
          roles: ['SYSADMIN'],
        },
        {
          name: 'Content',
          path: '/content/:membershipId',
          component: CanvaLinkContent,
          roles: ['USER', 'SYSADMIN'],
        },
        {
          name: 'Courses',
          path: '/courses',
          component: Courses,
          roles: ['SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Images',
          path: '/images',
          component: Images,
          roles: ['SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Image Tags',
          path: '/image-tags',
          component: ImageTags,
          roles: ['ADMIN', 'SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Image Tag',
          path: '/image-tag/:tagId',
          component: ImageTag,
          roles: ['ADMIN', 'SYSADMIN'],
        },
        {
          name: 'Image',
          path: '/image/:imageId',
          component: Image,
          roles: ['ADMIN', 'SYSADMIN'],
        },
        {
          name: 'Questionnaire',
          path: '/questionnaire',
          component: Questionnaire,
          roles: ['ADMIN', 'SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Edit Question',
          path: '/question/:questionId',
          component: QuestionnaireQuestion,
          roles: ['ADMIN', 'SYSADMIN'],
        },
        {
          name: 'Edit Answer',
          path: '/answer/:answerId',
          component: QuestionnaireAnswer,
          roles: ['ADMIN', 'SYSADMIN'],
        },
        {
          name: 'Store',
          path: '/store',
          component: Store,
          showInDrawer: true,
        },
        ...commonAppRoutes,
      ],
      cldCloudName: 'hzcju0e8n',
      cldUploadPreset: 'am9s6xuj',
    },
    sports: {
      homeRoutes: {
        SYSADMIN: '/leagues',
        ADMIN: '/leagues',
        COACH: '/teams',
      },
      appRoutes: [
        {
          name: 'Home',
          path: '/',
          component: ChefHome,
          showInDrawer: true,
        },
        {
          path: '/leagues',
          component: Leagues,
          name: 'Leagues',
          roles: ['ADMIN', 'SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Leagues',
          path: '/league/:leagueId',
          component: League,
          roles: ['ADMIN', 'SYSADMIN'],
        },
        {
          name: 'Divisions',
          path: '/division/:divisionId',
          component: ChefHome,
          roles: ['ADMIN', 'SYSADMIN'],
        },
        {
          name: 'Images',
          path: '/images',
          component: Images,
          roles: ['SYSADMIN'],
          showInDrawer: true,
        },
        {
          name: 'Teams',
          path: '/teams',
          component: CoachTeams,
          roles: ['COACH'],
          showInDrawer: true,
        },
        {
          name: 'Coaches',
          path: '/coaches',
          component: ChefHome,
          roles: ['ADMIN', 'SYSADMIN'],
        },
        ...commonAppRoutes,
      ],
      cldCloudName: 'hkgss7uwm',
      cldUploadPreset: 'tvlwmfw3',
    },
  };

  const config = useMemo(() => featureConfig[projectName] || {}, [projectName]);

  appRoutes = config.appRoutes;
  cldCloudName = config.cldCloudName;
  cldUploadPreset = config.cldUploadPreset;
  homeRoutes = config.homeRoutes;

  cld = useMemo(() => {
    if (!cldCloudName) return undefined;
    return new Cloudinary({
      cloud: {
        cloudName: cldCloudName,
      },
    });
  }, [cldCloudName]);

  const snackbarDataMemo = useMemo(() => snackbarData, [snackbarData]);
  const performingActionMemo = useMemo(
    () => performingAction,
    [performingAction],
  );
  const toggleMobileOpen = () => setMobileOpen(!mobileOpen);
  const mobileOpenMemo = useMemo(() => mobileOpen, [mobileOpen]);

  // Wrap the children with the ProjectSwitcherContext.Provider and pass the data arrays as values
  return (
    <ProjectContext.Provider
      value={{
        appName,
        projectName,
        webUrl,
        appRoutes,
        homeRoutes,
        cld,
        cldCloudName,
        cldUploadPreset,
        performingAction: performingActionMemo,
        setPerformingAction,
        mobileOpen: mobileOpenMemo,
        toggleMobileOpen,
        snackbarData: snackbarDataMemo,
        setSnackbar,
        isSmallScreen,
        isMediumScreen,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
