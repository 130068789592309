import { TypeEditTaskField, TypeTask } from '../../../types';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Collapse,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  ChevronRight,
  DeleteForever,
  ExpandMore,
  Restore,
} from '@mui/icons-material';
import React, { useContext, useState } from 'react';
import { ProjectContext } from '../../../providers';
import { format } from 'date-fns';
import { DateView, DurationView } from '../../_common';
import { AppearanceContext } from '../../../providers/AppearanceProvider';
import { Link } from 'react-router-dom';

type ViewTaskProps = {
  task: TypeTask;
  activeTask: TypeTask | null;
  onSetEditTask: (
    _task: TypeTask,
    _focusField: TypeEditTaskField | null,
  ) => void;
  onSave: (_task: TypeTask) => void;
};

export const ViewTaskCard = ({
  task,
  activeTask,
  onSetEditTask,
  onSave,
}: ViewTaskProps) => {
  const { performingAction, isMediumScreen } = useContext(ProjectContext);
  const { defaultTheme } = useContext(AppearanceContext);
  const [expanded, setExpanded] = useState<boolean>(false);

  const actions = () => (
    <Box display="flex" justifyContent="flex-end">
      <IconButton onClick={() => setExpanded(!expanded)}>
        {expanded ? <ExpandMore /> : <ChevronRight />}
      </IconButton>
      <IconButton
        onClick={() =>
          onSave({
            ...task,
            completedAt: task.completedAt
              ? undefined
              : format(new Date(), `yyyy-MM-dd'T'HH:mm:ss`),
          })
        }
        disabled={performingAction}
      >
        {task.completedAt ? <CheckBox /> : <CheckBoxOutlineBlank />}
      </IconButton>
      <IconButton
        onClick={() =>
          onSave({
            ...task,
            deletedAt: task.deletedAt
              ? undefined
              : format(new Date(), `yyyy-MM-dd'T'HH:mm:ss`),
          })
        }
        disabled={performingAction}
      >
        {task.deletedAt ? <Restore /> : <DeleteForever />}
      </IconButton>
    </Box>
  );

  const expansionView = () => (
    <>
      <Grid item xs={6} md={3}>
        <DateView title={'Start'} date={task.startAt} />
      </Grid>
      <Grid item xs={6} md={3}>
        <DateView title={'End'} date={task.endAt} />
      </Grid>
      <Grid item xs={6} md={3}>
        <DurationView title={'Duration'} duration={task.durationEstimate} />
      </Grid>
      <Grid item xs={6} md={3}>
        <DateView title={'Created'} date={task.createdAt} />
      </Grid>
      <Grid item xs={6} md={3}>
        <DateView title={'Due'} date={task.dueAt} />
      </Grid>
      <Grid item xs={6} md={3}>
        <DateView title={'Snooze'} date={task.snoozeUntil} />
      </Grid>
      <Grid item xs={6} md={3}>
        <DateView title={'Completed'} date={task.completedAt} />
      </Grid>
      <Grid item xs={6} md={3}>
        <DateView title={'Deleted'} date={task.deletedAt} />
      </Grid>
      <Grid item xs={12} md={6}>
        {task.tags?.map((tag) => (
          <Chip key={tag.id} sx={{ marginRight: 1 }} label={tag.name} />
        ))}
      </Grid>
      {task.taskLinks?.map((taskLink, index) => (
        <Grid key={`task-${task.id}-link-${index}`} item xs={12}>
          <Link to={taskLink.url ?? ''} target={'_blank'}>
            {taskLink.url}
          </Link>
        </Grid>
      ))}
    </>
  );

  if (isMediumScreen) {
    return (
      <>
        <ListItem
          sx={{
            backgroundColor:
              activeTask?.id === task.id
                ? defaultTheme.palette.primary.light
                : 'default',
            pb: 0,
            pt: 0,
          }}
        >
          <ListItemText
            onClick={() => onSetEditTask(task, 'name')}
            primary={task.name}
            sx={{ cursor: 'pointer' }}
          ></ListItemText>
          <IconButton onClick={() => setExpanded(!expanded)} edge={'end'}>
            {expanded ? <ExpandMore /> : <ChevronRight />}
          </IconButton>
        </ListItem>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <ListItem>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {actions()}
              </Grid>
              {expansionView()}
            </Grid>
          </ListItem>
        </Collapse>
      </>
    );
  }
  return (
    <Grid item xs={12}>
      <Card
        sx={{
          backgroundColor:
            activeTask?.id === task.id
              ? defaultTheme.palette.primary.light
              : 'default',
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            {isMediumScreen && (
              <Grid item xs={12}>
                {actions()}
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={9}
              onClick={() => onSetEditTask(task, 'name')}
            >
              <Typography variant={'body1'}>{task.name}</Typography>
            </Grid>
            {!isMediumScreen && (
              <Grid item md={3}>
                {actions()}
              </Grid>
            )}
            {expanded && expansionView()}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
