import React, { useContext } from 'react';
import { Button, Card, CardContent, Grid, TextField } from '@mui/material';
import { ArrowDownward, ArrowUpward, DeleteForever } from '@mui/icons-material';
import { TypeInstruction } from '../../../types';
import { genericSwap } from '../../_common';
import { ProjectContext, RecipeContext } from '../../../providers';
import { flexCenterStart } from '../../../data/styles';

type InstructionProps = {
  instruction: TypeInstruction;
  instructionIndex: number;
};

export const Instruction = ({
  instruction,
  instructionIndex,
}: InstructionProps) => {
  const { isMediumScreen } = useContext(ProjectContext);
  const { recipe, setRecipe } = useContext(RecipeContext);

  const handleInstructionChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    instructionIndex: number,
  ) => {
    const eventValue = event.target.value;
    setRecipe({
      ...recipe,
      instructionList: recipe?.instructionList?.map((instruction, index) => {
        if (index === instructionIndex) {
          instruction.instructionText = eventValue;
        }
        return instruction;
      }),
    });
  };

  const moveRowInstructionList = (instructionIndex: number, up: boolean) => {
    const instructionList = genericSwap<TypeInstruction>(
      recipe?.instructionList!!,
      instructionIndex,
      up,
    );
    setRecipe({
      ...recipe,
      instructionList: instructionList,
    });
  };

  const removeRowInstructionList = (instructionIndex: number) => {
    setRecipe({
      ...recipe,
      instructionList: recipe?.instructionList?.filter(
        (item, index) => index !== instructionIndex,
      ),
    });
  };

  const instructionFields = (
    <Grid container item spacing={2}>
      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          variant="outlined"
          label="Instruction"
          value={instruction.instructionText || ''}
          placeholder="Instruction"
          inputProps={{ maxLength: 1000 }}
          multiline
          maxRows={5}
          onChange={(event) => handleInstructionChange(event, instructionIndex)}
        />
      </Grid>
      <Grid item xs={12} md={4} style={flexCenterStart}>
        <Button
          variant="outlined"
          onClick={() => moveRowInstructionList(instructionIndex, true)}
          disabled={instructionIndex === 0}
          sx={{ marginRight: 2 }}
        >
          <ArrowUpward />
        </Button>
        <Button
          variant="outlined"
          onClick={() => moveRowInstructionList(instructionIndex, false)}
          disabled={instructionIndex === recipe.instructionList!!.length - 1}
          sx={{ marginRight: 2 }}
        >
          <ArrowDownward />
        </Button>
        <Button
          variant="outlined"
          onClick={() => removeRowInstructionList(instructionIndex)}
        >
          <DeleteForever />
        </Button>
      </Grid>
    </Grid>
  );

  return isMediumScreen ? (
    <Grid item xs={12}>
      <Card>
        <CardContent>{instructionFields}</CardContent>
      </Card>
    </Grid>
  ) : (
    instructionFields
  );
};
