import React, { useEffect, useState } from 'react';
import { Tags } from '../../_common';
import { TypeTagEntityMap } from '../../../types';
import { useGetTaskTagEntityMap } from '../../../api';

export const TaskTags = () => {
  const [map, setMap] = useState<TypeTagEntityMap>({});
  const { data: taskTagEntityMap } = useGetTaskTagEntityMap();

  useEffect(() => {
    if (!taskTagEntityMap) return;
    setMap(taskTagEntityMap);
  }, [taskTagEntityMap]);

  return <Tags type={'task'} mapFieldName={'Task'} map={map} />;
};
