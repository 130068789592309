import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { Features } from './Features';
import { TrustBadges } from './TrustBadges';
import { FAQ } from './FAQ';
import { Footer } from './Footer';

export const CoachHome = () => {
  // const { openDialog } = useContext(DialogContext);
  // const { user } = useContext(AuthContext);

  // const homeButton = {
  //   padding: '10px',
  //   borderRadius: '40px',
  //   color: '#050505',
  //   border: '2px solid #2EC1DC',
  //   width: '90%',
  // };
  //
  // const boxStyles = {
  //   display: 'flex',
  //   padding: '40px 0 !important',
  // };

  return (
    <Box>
      <Container>
        <Typography variant="h1">
          Transform Your Training with Personalized Workout Programs
        </Typography>
        <Typography variant="h2">
          Connect with expert coaches and access customized workout plans
          tailored to your goals and needs.
        </Typography>
        <Button variant="contained" color="primary">
          Get Started
        </Button>
      </Container>
      <Features />
      <TrustBadges />
      <FAQ />
      <Footer />
    </Box>
  );
};
