import React from 'react';
import { Box, Chip, Container, Grid } from '@mui/material';
import { useGetTagsByType } from '../../../api';
import { TypeTag } from '../../../types';

export type TagsHeaderProps = {
  type: string;
  selectedTags: TypeTag[];
  onSelectTag: (_tag: TypeTag) => void;
};

export const TagsHeader = ({
  type,
  selectedTags,
  onSelectTag,
}: TagsHeaderProps) => {
  const { data } = useGetTagsByType(type);

  return (
    <Box mt={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {data?.map((tag) => (
              <Chip
                sx={{ mr: 1, mb: 1 }}
                key={tag.id}
                label={tag.name}
                color={
                  selectedTags.some((s) => s.id === tag.id)
                    ? 'primary'
                    : 'default'
                }
                onClick={() => onSelectTag(tag)}
              />
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
